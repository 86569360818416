'use client';

import { FilterImageCategory } from "@/components/features/gallery/hooks/useGalleryCategory";
import { DeviceDetector } from "@/lib/utils/device-util";
import React, { useContext, createContext, useState, ReactNode, useOptimistic } from "react";

// 画面の表示タイプ
export type ScreenType = "grid" | "fullscreen" | "list" | "carousel" | "random";

/**
 * 指定された値が ScreenType のいずれかであるかを判定するバリデーション関数
 * @param value チェック対象の値
 * @returns `true` (有効) または `false` (無効)
 */
export const isValidScreenType = (value: unknown): value is ScreenType => {
  return (
    typeof value === "string" &&
    (["grid", "fullscreen", "list", "carousel", "random"] as const).includes(value as ScreenType)
  );
};


type SettingMenuProps = {
  isMeneuOpen: boolean;
};

type VisableMode = {
  isShow: boolean;
};
// アプリケーションで共通使用するコンテキストのプロパティ定義
type AppContextProps = {
  isMobile: boolean;
  settingMenuProps: SettingMenuProps;
  setSettingMenuProps: (settingMenuProps: SettingMenuProps) => void;
  visableMode: VisableMode;
  setVisableMode: (visableMode: VisableMode) => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  screenType: ScreenType;
  sendingFollwoerIds: string[];
  setSendingFollwoerIds: (value: string[]) => void;
  setScreenType: (mode: ScreenType) => void;
  isSendingFollow: boolean,
  setIsSendingFollow: (value: boolean) => void;
  activeCategory: FilterImageCategory;
  setActiveCategory: React.Dispatch<React.SetStateAction<FilterImageCategory>>;
};

// コンテキストを作成
const AppContext = createContext<AppContextProps>({
  activeCategory: 'Latest',
  setActiveCategory: () => undefined,
  settingMenuProps: {
    isMeneuOpen: false,
  },
  setSettingMenuProps: (settingMenuProps: SettingMenuProps) => { },
  visableMode: {
    isShow: true,
  },
  setVisableMode: (visableMode: VisableMode) => { },
  isMobile: false,
  isModalOpen: false,
  setIsModalOpen: (isModalOpen: React.SetStateAction<boolean>) => { },
  isUploading: false,
  setIsUploading: (isUploading: boolean) => { },
  loading: false,
  setLoading: (loading: boolean) => { },
  screenType: "grid",
  setScreenType: (mode: ScreenType) => { },
  sendingFollwoerIds: [],
  setSendingFollwoerIds: (value: string[]) => { },
  isSendingFollow: false,
  setIsSendingFollow: (value: boolean) => { },
});

// プロバイダーコンポーネント
export const AppContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [settingMenuProps, setSettingMenuProps] = useState<SettingMenuProps>({
    isMeneuOpen: false,
  });

  const [visableMode, setVisableMode] = useState<VisableMode>({
    isShow: true,
  });
  const [sendingFollwoerIds, setSendingFollwoerIds] = useState<string[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [screenType, setScreenType] =
    useState<ScreenType>("grid");

  const [isMobile] = useState(
    new DeviceDetector().isAndroid() ||
    new DeviceDetector().isiPhone ||
    new DeviceDetector().isiPad()
  );

  const [isSendingFollow, setIsSendingFollow] = useState(false);
  const [activeCategory, setActiveCategory] = useState<FilterImageCategory>('Latest');

  return (
    <AppContext.Provider
      value={{
        activeCategory,
        setActiveCategory,
        setSettingMenuProps,
        settingMenuProps,
        visableMode,
        setVisableMode,
        isModalOpen,
        setIsModalOpen,
        isUploading,
        setIsUploading,
        isMobile,
        loading,
        setLoading,
        screenType,
        setScreenType,
        sendingFollwoerIds,
        setSendingFollwoerIds,
        isSendingFollow,
        setIsSendingFollow,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// コンテキストを使用するためのフック
export const useAppContext = (): AppContextProps =>
  useContext<AppContextProps>(AppContext);

export default AppContextProvider;
