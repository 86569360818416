import { BackendURLContantas } from "@/constants/URLConstants";
import { axios } from "@/lib/axios";

import { AxiosResponse } from "axios";
import { UserProfile } from "../user";
import { ImageResponseData } from "../image";

export type GroupPendingUsersCountRequest = {
  userId: string;
  groupId?: number | null;
}

export type GroupPendingUsersCountResponse = {
  count: number;
}

export type GroupType = 1 | 2 | 3;

export const GroupTypeValue = {
  PUBLIC: 1,
  PRIVATE: 2,
  PUBLIC_PERMISSION: 3,
} as const;

export type GroupsIndexRequest = {
  userUuid: string;
  groupType: GroupType;
}

export type GroupApprovalPendingRequest = {
  userUuid: string;
  groupType: GroupType;
  status: GroupUserStatus;
}

export type GetAdditionalMembersRequest = {
  groupId: number;
  userUuid: string;
}

export type getAdditionalMembersResponse = {
  data: UserProfile[];
  currentPage: number;
  lastPage: number;
  total: number;
}


export type GroupGetMembersRequest = {
  groupId: number;
  userId: string;
  containPendingMembers?: boolean;
}

export type GroupGetMembersResponse = {
  data: GroupMemberResponseData[];
  currentPage: number;
  lastPage: number;
  total: number;
}

export type GroupMemberResponseData = {
  status: GroupUserStatus,
} & UserProfile

export type GetGroupMembersResponseData = {
  items: GroupMemberResponseData[];
  isLoading: boolean;
  isError: boolean;
  loadMore: () => void;
  hasMore: boolean,
}

// 申請中ユーザー
export type GroupGetPendingUsersRequest = GroupGetMembersRequest;
export type GroupGetPendingUsersResponse = GroupGetMembersResponse;

export type GroupJoinedUser = {
  groupId: number;
  id: number;
  role: number;
  userProfile: UserProfile;
  userUuid: string;
}

// グループ一覧
export type GroupDataResponse = {
  id: number;
  name: string;
  url1?: string | null;
  url2?: string | null;
  ownerUuid: string;
  iconFileUrl: string | null;
  iconName: string | null;
  members: number;
  desc: null | string;
  groupType: GroupType;
  createdAt: string;
  updatedAt: string;
  adminIds: string[];
  users: GroupJoinedUser[];
  pendingUserIds: string[];
  adminPendingUserIds: string[];
  approvingUserIds: string[];
  isParticipateOrDeclineCount: number;
  applyNotificationCount: number;
  memberNotificationCount: number;
  adminPermissionNotifyCount: number;
  participationNotifiyCount: number;
  isAdminPending: boolean;
  isParticipantPending: boolean;
  isJoin: boolean;
}

export type GroupIndexResponse = {
  data: GroupDataResponse[];
  currentPage: number;
  lastPage: number;
  total: number;
}

export type GroupStoreRequest = {
  name: string;
  userUuid: string;
  // iconName: string | null;
  // iconFileUrl: string | null;
  file: File | null,
  userIds: string[];
  desc: null | string;
  groupType: GroupType
}

export type GroupStoreResponse = {
  id: number;
  name: string;
  ownerUuid: string;
  iconName: string | null;
  iconFileUrl: string | null;
}


export type GroupJoinOrDeclineRequest = {
  status: GroupUserStatus;
  userId: string;
  requestUserId: string;
  groupId: number;
}


export type GroupJoinOrDeclineResponse = {
  status: GroupUserStatus;
}

export type GroupMemberAcceptRequest = {
  status: GroupUserStatus;
  userId: string;
  allowUserId: string;
  groupId: number;
}

export type GroupMemberAcceptResponse = {
  status: GroupUserStatus;
}


export type GroupMemberDeleteRequest = {
  id: number;
  userIds: string[];
}

export type GroupMemberDeleteResponse = {
  id: number;
  name: string;
  ownerUuid: string;
  iconName: string | null;
  iconFileUrl: string | null;
}

export type GroupUpdateRequest = {
  id: number;
  userUuid: string;
  name: string;
  file: File | null;
  // iconFileUrl: null | string;
  // iconName: null | string;
  desc: null | string;
  groupType: GroupType;
  url1?: string;
  url2?: string;
}

// グループ一覧
export type GroupUpdateResponse = {
  data: GroupDataResponse;
  status: number;
}

export type GroupScoreInfoRequest = {
  groupId: number;
}

export type GroupScoreInfoResponse = {
  totalScore: number;
  posts: number;
  averageScore: number;
  members: number;
}

export type GroupAddMembersRequest = {
  id: number;
  userIds: string[];
  userId: string;
}

export type GroupDeleteMembersRequest = GroupAddMembersRequest;

export type GroupFindRequest = {
  id: number;
  userId: string;
}


export type GroupsNotifyRequest = {
  userId: string;
}

export type GroupsNotifyResponse = {
  exists: boolean;
}



export type GroupFindResponse = {
  group: GroupDataResponse;
  message: string;
}

export type GroupGetWithImageRequest = {
  userId: string;
  imageId: number;
}

export type GroupGetWithImageResponse = {
  group: GroupDataResponse | null;
}

export type GroupAddMembersResponse = {
  message: string;
  group: GroupDataResponse;
}

export type GroupDeleteMembersResponse = GroupAddMembersResponse;


export type Group = {
  message: string;
  group: GroupDataResponse;
}

export type GroupCreateResponse = {
  data: UserProfile[];
  currentPage: number;
  lastPage: number;
  total: number;
};

export type GroupExistsJoinMemberRequest = {
  userId: string;
  groupId: number;
}

export type GroupExistsJoinMemberResponse = {
  exists: boolean;
  ownerId: string;
  status: GroupUserStatus;
}


export type GroupExistsPneidngUserResponse = {
  exists: boolean;
  status: GroupUserStatus;
}

export const GroupDeleteTypeValues = {
  RemoveImgFromGroup: 1,
  DeleteGroup: 2,
}

export type GroupPostJoinOrLeaveRequest = {
  userId: string;
  groupId: number;
  deleteType?: number;
};

export type GroupPostJoinOrLeaveResponse = {
  status: GroupUserStatus;
} & GroupExistsJoinMemberResponse;

export type GroupRequestJoinRequest = {
  userId: string;
  groupId: number;
}


export type GroupUserStatus = 'noPermit' | 'unapproved' | 'pending' | 'approved' | 'rejected' | 'joined' | 'adminApproved';

export type GroupRequestJoinResponse = {
  status: GroupUserStatus;
}

export type GroupApplicationStatusRequest = {
  userId: string;
  groupId: number;
}

export type GroupetApplicationStatusResponse = {
  status: GroupUserStatus;
  numOfAdmin: number;
}

export type GroupGrantAdminRoleRequest = {
  userId: string;
  targetUserId: string;
  groupId: number;
}

export const GroupRoleValue = {
  ADMIN: 1,
  PARTICIPANT: 2,
} as const;

export type GroupGrantAdminRoleResponse = {
  role: number;
}


export type GroupsGetAllImagesRequest = {
  userId: string;
}

export type GroupsGetAllImagesResponse = {
  data: ImageResponseData[];
  currentPage: number;
  lastPage: number;
}

export const GroupApi = {
  index: async (request: GroupsIndexRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupsIndexRequest,
      AxiosResponse<GroupIndexResponse>
    >(`${BackendURLContantas.GROUP.INDEX.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },
  getApprovalPending: async (request: GroupApprovalPendingRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupApprovalPendingRequest,
      AxiosResponse<GroupIndexResponse>
    >(`${BackendURLContantas.GROUP.GET_APPROVAL_PENDING.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },

  find: async (request: GroupFindRequest) => {
    const { data } = await axios.post<
      GroupsIndexRequest,
      AxiosResponse<GroupDataResponse>
    >(`${BackendURLContantas.GROUP.FIND.PATH_NAME}`,
      request,
    );

    return data;
  },
  notify: async (request: GroupsNotifyRequest) => {
    const { data } = await axios.post<
      GroupsIndexRequest,
      AxiosResponse<GroupsNotifyResponse>
    >(`${BackendURLContantas.GROUP.NOTIFY.PATH_NAME}`,
      request,
    );

    return data;
  },

  private: async (request: GroupsIndexRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupsIndexRequest,
      AxiosResponse<GroupIndexResponse>
    >(`${BackendURLContantas.GROUP.PRIVATE.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },

  getGroupMembers: async (request: GroupGetMembersRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupGetMembersRequest,
      AxiosResponse<GroupGetMembersResponse>
    >(`${BackendURLContantas.GROUP.GET_MEMBERS.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },
  getGroupPendingUsers: async (request: GroupGetPendingUsersRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupGetPendingUsersRequest,
      AxiosResponse<GroupGetPendingUsersResponse>
    >(`${BackendURLContantas.GROUP.GET_PENDING_MEMBERS.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },


  create: async (request: GroupsIndexRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupsIndexRequest,
      AxiosResponse<GroupCreateResponse>
    >(`${BackendURLContantas.GROUP.CREATE.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },

  store: async (request: GroupStoreRequest) => {
    const { name,
      userUuid,
      file,
      userIds,
      desc,
      groupType,
    } = request;
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    formData.append('userUuid', userUuid);
    // Laravel が期待する形式に変換する
    userIds.forEach((userId) => {
      formData.append("userIds[]", userId);
    });
    formData.append('name', name);
    formData.append('desc', desc ?? '');
    formData.append('groupType', groupType.toString());

    const { data } = await axios.post<
      GroupStoreRequest,
      AxiosResponse<GroupStoreResponse>
    >(`${BackendURLContantas.GROUP.STORE.PATH_NAME}`,
      formData,
      {
        headers: {
          "Content-type": "multipart/form-data",
        }
      }
    );

    return data;
  },
  update: async (request: GroupUpdateRequest) => {
    const { name,
      id,
      userUuid,
      file,
      desc,
      groupType,
      url1,
      url2,
    } = request;

    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    formData.append('id', id.toString());
    formData.append('userUuid', userUuid);
    formData.append('url1', url1 ?? '');
    formData.append('url2', url2 ?? '');
    formData.append('name', name);
    formData.append('desc', desc ?? '');
    formData.append('groupType', groupType.toString());

    const { data } = await axios.post<
      GroupUpdateRequest,
      AxiosResponse<GroupUpdateResponse>
    >(`${BackendURLContantas.GROUP.UPDATE.PATH_NAME}`,
      formData,
      {
        headers: {
          "Content-type": "multipart/form-data",
        }
      }
    );

    return data;
  },
  addMembers: async (request: GroupAddMembersRequest) => {
    const { data } = await axios.post<
      GroupStoreRequest,
      AxiosResponse<GroupAddMembersResponse>
    >(`${BackendURLContantas.GROUP.ADD_MEMBERS.PATH_NAME}`,
      request,
    );

    return data;
  },

  deleteMembers: async (request: GroupDeleteMembersRequest) => {
    const { data } = await axios.post<
      GroupDeleteMembersRequest,
      AxiosResponse<GroupDeleteMembersResponse>
    >(`${BackendURLContantas.GROUP.DELETE_MEMBERS.PAT_HNAME}`,
      request,
    );

    return data;
  },

  addAuthMembers: async (request: GroupDeleteMembersRequest) => {
    const { data } = await axios.post<
      GroupDeleteMembersRequest,
      AxiosResponse<GroupDeleteMembersResponse>
    >(`${BackendURLContantas.GROUP.ADD_AUTH_MEMBERS.PAT_HNAME}`,
      request,
    );

    return data;
  },

  getAdditionalMembers: async (request: GetAdditionalMembersRequest, page: number = 1) => {
    const { data } = await axios.post<
      GroupStoreRequest,
      AxiosResponse<GroupGetMembersResponse>
    >(`${BackendURLContantas.GROUP.GET_ADDTIONAL_MEMBERS.PATH_NAME}?page=${page}`,
      request,
    );

    return data;
  },
  updateIcon: async (request: GroupUpdateRequest) => {
    const { data } = await axios.post<
      GroupUpdateRequest,
      AxiosResponse<GroupUpdateResponse>
    >(`${BackendURLContantas.GROUP.UPDATE_ICON.PATH_NAME}`,
      request,
    );

    return data;
  },
  getGroupScoreInfo: async (request: GroupScoreInfoRequest) => {
    const { data } = await axios.post<
      GroupScoreInfoRequest,
      AxiosResponse<GroupScoreInfoResponse>
    >(`${BackendURLContantas.GROUP.SCORE_INFO.PATH_NAME}`,
      request,
    );

    return data;
  },
  existsJoinMember: async (request: GroupExistsJoinMemberRequest) => {
    const { data } = await axios.post<
      GroupExistsJoinMemberRequest,
      AxiosResponse<GroupExistsJoinMemberResponse>
    >(`${BackendURLContantas.GROUP.EXISTS_JOIN_MEMBER.PATH_NAME}`,
      request,
    );

    return data;
  },
  postJoinOrLeave: async (request: GroupPostJoinOrLeaveRequest) => {
    const { data } = await axios.post<
      GroupPostJoinOrLeaveRequest,
      AxiosResponse<GroupPostJoinOrLeaveResponse>
    >(`${BackendURLContantas.GROUP.POST_JOIN_OR_LEAVE.PATH_NAME}`,
      request,
    );

    return data;
  },
  requestJoin: async (request: GroupRequestJoinRequest) => {
    const { data } = await axios.post<
      GroupRequestJoinRequest,
      AxiosResponse<GroupRequestJoinResponse>
    >(`${BackendURLContantas.GROUP.REQUEST_JOIN.PATH_NAME}`,
      request,
    );

    return data;
  },
  requestAdminJoin: async (request: GroupRequestJoinRequest) => {
    const { data } = await axios.post<
      GroupRequestJoinRequest,
      AxiosResponse<GroupRequestJoinResponse>
    >(`${BackendURLContantas.GROUP.REQUEST_ADMIN_JOIN.PATH_NAME}`,
      request,
    );

    return data;
  },
  applicationStatus: async (request: GroupApplicationStatusRequest) => {
    const { data } = await axios.post<
      GroupAddMembersRequest,
      AxiosResponse<GroupetApplicationStatusResponse>
    >(`${BackendURLContantas.GROUP.APPLICATION_STATUS.PATH_NAME}`,
      request,
    );

    return data;
  },
  memberAccept: async (request: GroupMemberAcceptRequest) => {
    const { data } = await axios.post<
      GroupMemberAcceptRequest,
      AxiosResponse<GroupMemberAcceptResponse>
    >(`${BackendURLContantas.GROUP.MEMBER_ACCEPT.PATH_NAME}`,
      request,
    );

    return data;
  },
  getPendingUsersCount: async (request: GroupPendingUsersCountRequest) => {
    const { data } = await axios.post<
      GroupPendingUsersCountRequest,
      AxiosResponse<GroupPendingUsersCountResponse>
    >(
      `${BackendURLContantas.GROUP.PENDING_USERS_COUNT.PATH_NAME}`,
      request,
    );

    return data;
  },
  joinOrDecline: async (request: GroupJoinOrDeclineRequest) => {
    const { data } = await axios.post<
      GroupJoinOrDeclineRequest,
      AxiosResponse<GroupJoinOrDeclineResponse>
    >(
      `${BackendURLContantas.GROUP.JOIN_OR_DECLINE.PATH_NAME}`,
      request,
    );

    return data;
  },
  existsPendingUser: async (request: GroupExistsJoinMemberRequest) => {
    const { data } = await axios.post<
      GroupExistsJoinMemberRequest,
      AxiosResponse<GroupExistsPneidngUserResponse>
    >(`${BackendURLContantas.GROUP.EXISTS_JOIN_MEMBER.PATH_NAME}`,
      request,
    );

    return data;
  },
  getGroupWithImage: async (request: GroupGetWithImageRequest) => {
    const { data } = await axios.post<
      GroupGetWithImageRequest,
      AxiosResponse<GroupDataResponse>
    >(`${BackendURLContantas.GROUP.GET_GROUP_WITH_IMAGE.PATH_NAME}`,
      request,
    );

    return data;
  },
  getAllGroupsImages: async (request: GroupsGetAllImagesRequest, page: number) => {
    const { data } = await axios.post<
      GroupsGetAllImagesRequest,
      AxiosResponse<GroupsGetAllImagesResponse>
    >(`${BackendURLContantas.GROUP.GET_ALL_GROUPS_IMAGES.PATH_NAME}`,
      request,
    );

    return data;
  },
}
