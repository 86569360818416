import axios from "axios";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_REST_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common = {
  Accept: "*/*",
  "Content-Type": "application/json",
};
axios.defaults.withCredentials = true;


// Set the Bearer auth token.
const setBearerToken = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};


const fetchCsrfToken = (): Promise<{
  sessionKey: string | null;
  xsrfToken: string | null;
}> => {
  return new Promise<{ sessionKey: string | null; xsrfToken: string | null }>(
    (resolve, reject) => {
      const csrfUrl = `${process.env.NEXT_PUBLIC_REST_BASE_URL}/sanctum/csrf-cookie`;

      fetch(csrfUrl, { method: "GET", credentials: "include" })
        .then((res) => {
          const setCookieHeader = res.headers.get("set-cookie");
          const cookies = setCookieHeader?.split(", ");
          let sessionKey = null;
          let xsrfToken = null;

          for (const cookie of cookies || []) {
            if (cookie.startsWith("laravel_session=")) {
              sessionKey = cookie.split("=")[1].split(";")[0];
            } else if (cookie.startsWith("XSRF-TOKEN=")) {
              xsrfToken = cookie.split("=")[1].split(";")[0];
            }

            if (sessionKey && xsrfToken) {
              resolve({ sessionKey, xsrfToken });
              return;
            }
          }

          reject(new Error("CSRFトークンの取得に失敗しました。"));
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
};



export { axios, setBearerToken, fetchCsrfToken };
