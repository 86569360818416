import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { DeviceDetector } from "./utils/device-util";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


export function isIntegerString(str: string) {
  return /^\d+$/.test(str);
}

export function trimSpaces(str: string): string {
  return str.replace(/^\s+|\s+$/g, '').replace(/^[\u3000]+|[\u3000]+$/g, '');
}

export function copyToClipboard(text: string, callback?: () => void) {
  if (typeof window === 'undefined') return;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    // **スマホでは通知を抑止**
    Object.defineProperty(navigator, "clipboard", {
      value: null,
      configurable: true,
      writable: true,
    });

    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      if (callback) callback();
    } catch (err) {
      console.error("コピー失敗:", err);
    }
    document.body.removeChild(textarea);
  } else {
    // **PCでは通常のクリップボード API を使用**
    navigator.clipboard.writeText(text).then(() => {
      if (callback) callback();
    }).catch(err => {
      console.error("コピーに失敗しました:", err);
    });
  }
}

export const isNumber = (value: any) => {
  return (typeof value === "number" && !isNaN(value))
}

/**
 * 全角文字を2カウント、半角文字を1カウントとして文字数を計算する関数
 * @param value 
 * @returns 
 */
export function countStringLength(value: string | null) {
  let count = 0;
  if (value === null) {
    return count;
  }

  for (let i = 0; i < value.length; i++) {
    const c = value.charCodeAt(i);
    // 単純な全角/半角チェック（より複雑なロジックが必要な場合は適宜調整）
    if ((c >= 0x0000 && c <= 0x00FF) || (c >= 0xFF61 && c <= 0xFF9F)) {
      count += 1; // 半角文字
    } else {
      count += 2; // 全角文字
    }
  }
  return count;
}

/**
 *  URLのバリデーション関数
 * @param url 
 * @returns 
 */
export function isValidUrl(url: string): boolean {

  const pattern = new RegExp('^(https?:\\/\\/)?' + // プロトコル
    '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // ドメイン名
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP形式のアドレス
    '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // ポート番号およびパス
    '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // クエリに含まれる可能性のある文字
    '(\\#[-a-zA-Z\\d_]*)?$', 'i'); // フラグメント識別子

  return !!pattern.test(url);
}


/**
 * 引数で指定したpx表示未満の場合は縮小して表示する
 * @param threshold 
 * @returns 
 */
export const adjustViewport = (threshold: number = 352) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return; // サーバーサイドではスキップ
  }

  const viewport = document.querySelector('meta[name="viewport"]');
  if (!viewport) {
    console.error('Viewport meta tag is not defined in the document.');
    return;
  }

  function switchViewport() {
    const newValue = window.innerWidth > threshold
      ? 'width=device-width,initial-scale=1'
      : `width=${threshold}`;

    if (viewport && viewport.getAttribute('content') !== newValue) {
      viewport.setAttribute('content', newValue);
    }

    // **フッターの高さを修正**
    fixFooterHeight();
  }

  const fixFooterHeight = () => {
    const footer = document.querySelector('footer');
    if (!footer) return;

    // **1. 一度高さをリセット**
    footer.style.height = "auto";

    // **2. `window.innerHeight` を適用**
    setTimeout(() => {
      footer.style.height = `${window.innerHeight}px`;
    }, 1000); // 高さが正しく取得できるまで 1 秒待つ
  };

  window.addEventListener('orientationchange', () => {
    setTimeout(switchViewport, 300);
    // 画面が回転した後に高さを再適用
    setTimeout(fixFooterHeight, 1000);
  });

  window.addEventListener('resize', switchViewport, false);
  switchViewport();
};

export function mergeArraysByKey<T, K extends keyof T>(
  key: K,
  ...arrays: T[][]
): T[] {
  // マップでキーとオブジェクトを管理
  const seenKeys = new Map<T[K], T>();

  for (const array of arrays) {
    for (const item of array) {
      if (!seenKeys.has(item[key])) {
        // オブジェクトを登録
        seenKeys.set(item[key], item);
      }
    }
  }

  // Map の登録順に基づき結果を生成
  return Array.from(seenKeys.values());
}

export const formatNumber = (number: number): string => {
  return number.toLocaleString();
};


export const extractNumber = (path: string): number | null => {
  const match = path.match(/^\/group\/mypage\/([^/]+)$/);
  if (match) {
    const potentialNumber = match[1];
    const parsedNumber = parseInt(potentialNumber, 10);
    return !isNaN(parsedNumber) ? parsedNumber : null;
  }
  return null;
};

export const isHttps = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "https:";
  } catch (error) {
    console.error("Invalid URL:", error);
    return false; // URLが無効な場合は`false`を返す
  }
}

export const isEnglishWord = (word: string): boolean => {
  return /^[A-Za-z]+$/.test(word);
}

export const removeDuplicates = <T, K extends keyof T>(array: T[], key: K): T[] => {
  return Array.from(new Map(array.map(item => [item[key], item])).values());
};

export const getRandomNumberInRange = ({ min, max, }: { min: number, max: number }): number => {
  if (min > max) throw new Error("min は max 以下である必要があります");
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getPathParam = (pathname: string, basePath: string): string | null => {
  // 先頭の `/` を削除
  const cleanPath = pathname.startsWith("/") ? pathname.substring(1) : pathname;

  // `basePath` で始まるか確認
  if (!cleanPath.startsWith(basePath)) {
    return null; // `basePath` に一致しない場合は `null`
  }

  // `basePath` を削除して、その後の値を取得
  const param = cleanPath.replace(basePath, "").replace(/^\/+/, ""); // 先頭の `/` も削除

  return param || null; // 値がない場合は `null`
};

export const isMobile = () => (
  new DeviceDetector().isAndroid() ||
  new DeviceDetector().isiPhone ||
  new DeviceDetector().isiPad());
